import {
	Create,
	Datagrid,
	DateField,
	DeleteButton,
	Edit,
	EditButton,
	List,
	NumberInput,
	NumberField,
	SimpleForm,
	SimpleList,
	TextField,
	TextInput,
	DateTimeInput,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { CreateToolbar, TopBarActions } from './components';

export const ActivitiesList = (props) => {
	const isSmall = useMediaQuery('(max-width:600px)');
	return (
		<List {...props} title='积分活动管理列表'>
			{isSmall ? (
				<SimpleList
					primaryText={(record) => `积分活动名称`}
					secondaryText={(record) => `${record.name.zh} ${record.name.ug}`}
					tertiaryText={(record) => `倍数 ${record.multiple}`}
				/>
			) : (
				<Datagrid>
					<TextField source='name.zh' label='积分活动名称（中文）' />
					<TextField source='name.ug' label='积分活动名称（维文）' />
					<NumberField source='multiple' label='倍数' />
					<DateField showTime={true} source='started_at' label='开始时间' />
					<DateField showTime={true} source='ended_at' label='结束时间' />
					<EditButton />
					<DeleteButton confirmTitle='' mutationMode='pessimistic' />
				</Datagrid>
			)}
		</List>
	);
};

export const ActivitiesEdit = (props) => {
	return (
		<Edit {...props} title='积分活动管理编辑' actions={<TopBarActions />}>
			<SimpleForm>
				<DateTimeInput source='started_at' label='开始时间' />
				<DateTimeInput source='ended_at' label='结束时间' />
			</SimpleForm>
		</Edit>
	);
};

export const ActivitiesCreate = (props) => {
	return (
		<Create {...props} title='积分活动管理创建' actions={<TopBarActions />}>
			<SimpleForm toolbar={<CreateToolbar />}>
				<TextInput source='name.zh' label='积分活动名称（中文）' />
				<TextInput source='name.ug' label='积分活动名称（维文）' />
				<NumberInput source='multiple' label='倍数' />
				<DateTimeInput source='started_at' label='开始时间' />
				<DateTimeInput source='ended_at' label='结束时间' />
			</SimpleForm>
		</Create>
	);
};
