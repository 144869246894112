import { makeStyles } from "@material-ui/core";
import {
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  TextField,
} from "ra-ui-materialui";
import { Create, Edit, SimpleForm, TextInput } from "react-admin";
import { CreateToolbar, TopBarActions } from "../components";

const useStyles = makeStyles(() => ({
  ugTitle: {
    fontFamily: "UgFont",
  },
}));

export const IndustriesList = (props) => {
  const classes = useStyles();
  return (
    <List {...props} title="店铺业态">
      <Datagrid>
        <TextField source="name.zh" label="中文名称" />
        <TextField
          source="name.ug"
          label="维文名称"
          className={classes.ugTitle}
        />

        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

const CatTitle = ({ record }) => {
  return <span>业态名 {record ? `"${record.name.zh}"` : ""}</span>;
};

export const IndustriesEdit = (props) => {
  return (
    <Edit {...props} title={<CatTitle />} actions={<TopBarActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput source="name.zh" label="中文名称" />
        <TextInput source="name.ug" label="维文名称" />
      </SimpleForm>
    </Edit>
  );
};

export const IndustriesCreate = (props) => {
  return (
    <Create {...props} actions={<TopBarActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput source="name.zh" label="中文名称" />
        <TextInput source="name.ug" label="维文名称" />
      </SimpleForm>
    </Create>
  );
};
