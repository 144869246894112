import { useState } from "react";
import {
  AutocompleteArrayInput,
  BooleanInput,
  DateTimeInput,
  Edit,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  useRecordContext,
} from "react-admin";
import { TopBarActions } from "../components";
import { imageInputFormat } from "../constants";

const MemberGiftEdit = (props) => {
  const optionTextName = (record) => `${record?.name.zh}`;
  const ShopIdsInput = () => {
    const gift = useRecordContext();
    console.log("🚀 ~ MemberGiftEdit ~ gift:", gift);
    const [available_for_all_shops, setShowShopIds] = useState(
      gift?.available_for_all_shops,
    );
    return (
      <>
        <BooleanInput
          source="available_for_all_shops"
          format={(r) => r === 1}
          parse={(i) => (i === true ? 1 : 0)}
          defaultValue={available_for_all_shops}
          onChange={() => setShowShopIds(!available_for_all_shops)}
          name="available_for_all_shops"
          color="primary"
        />
        {!available_for_all_shops && (
          <ReferenceArrayInput
            source="shop_ids"
            reference="shops"
            label="商铺"
            required
            fullWidth
          >
            <AutocompleteArrayInput optionText={optionTextName} />
          </ReferenceArrayInput>
        )}
      </>
    );
  };
  return (
    <Edit {...props} title="编辑礼品" actions={<TopBarActions />}>
      <SimpleForm>
        <ReferenceArrayInput
          source="level_ids"
          reference="member/levels"
          label="领取等级"
          required
          fullWidth
        >
          <AutocompleteArrayInput optionText={optionTextName} />
        </ReferenceArrayInput>
        <ShopIdsInput />
        <TextInput source="name.zh" label="中文名称" />
        <TextInput source="name.ug" label="维文名称" />
        <DateTimeInput source="ended_at" label="有效期限" required />
        <NumberInput source="exchange_points" label="所需积分" required />
        <NumberInput source="limit_per_user" label="每人限领" required />
        <NumberInput source="quantity" label="优惠券总数" required />
        <NumberInput source="market_price" label="市场价格" required />
        <NumberInput source="price" label="价格" required />
        <BooleanInput
          label="状态"
          source="status"
          format={(r) => r === 1}
          parse={(i) => (i === true ? 1 : 0)}
          defaultValue={1}
        />
        <ImageInput
          source="image.zh"
          label="中文图片"
          accept="image/*"
          format={imageInputFormat}
        >
          <ImageField source="src" />
        </ImageInput>
        <ImageInput
          source="image.ug"
          label="维文图片"
          accept="image/*"
          format={imageInputFormat}
        >
          <ImageField source="src" />
        </ImageInput>
        <TextInput
          source="description.zh"
          label="中文说明"
          multiline
          fullWidth
          rows={6}
        />
        <TextInput
          source="description.ug"
          label="维文说明"
          multiline
          fullWidth
          rows={6}
          style={{ textAlign: "right", direction: "rtl" }}
        />
        <TextInput
          source="gift_description.zh"
          label="中文说明"
          multiline
          fullWidth
          rows={6}
          required
        />
        <TextInput
          source="gift_description.ug"
          label="汉语说明"
          multiline
          fullWidth
          rows={6}
          style={{ textAlign: "right", direction: "rtl" }}
          required
        />
      </SimpleForm>
    </Edit>
  );
};

export default MemberGiftEdit;
