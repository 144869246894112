import {
	ChipField,
	Create,
	Datagrid,
	DeleteButton,
	Edit,
	EditButton,
	Filter,
	ImageField,
	ImageInput,
	List,
	NumberField,
	NumberInput,
	ReferenceArrayField,
	ReferenceField,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	SimpleList,
	SingleFieldList,
	TextField,
	TextInput,
	useRecordContext,
} from 'react-admin';
import { useMediaQuery, makeStyles, Avatar, Modal } from '@material-ui/core';
import { currencyProps } from './constants';
import { CreateToolbar, TopBarActions } from './components';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
	ugTitle: {
		fontFamily: 'UgFont',
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
	},
	pic: {
		'& img': {
			width: '50px',
			height: 'auto',
		},
	},
}));

const ShopsFilter = (props) => (
	<Filter {...props}>
		<TextInput label='搜索' source='name' alwaysOn />
	</Filter>
);

const ShopLogo = ({ source }) => {
	const record = useRecordContext();
	return <Avatar variant='rounded' src={record[source]} />;
};

export const ShopsList = (props) => {
	const [open, setOpen] = useState(false);
	const [img, setImg] = useState('');
	const isSmall = useMediaQuery('(max-width:600px)');
	const classes = useStyles();

	const handleImg = (url) => {
		setImg(url.target?.currentSrc);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<List {...props} title='店铺' filters={<ShopsFilter />}>
				{isSmall ? (
					<SimpleList
						primaryText={(record) => (
							<span>
								名称 {record.name.zh}
								<span className={classes.ugTitle}>{record.name.ug}</span>
							</span>
						)}
						secondaryText={(record) => (
							<span className={classes.column}>
								<span>{`每日营业额预警（元）${
									record.per_day_alert ?? 0
								}`}</span>
								<span>{`每单金额预警（元）${
									record.per_order_alert ?? 0
								}`}</span>
								<span>{`积分规则（元/积分） ${record.exhange_rate ?? 0}`}</span>
							</span>
						)}
					/>
				) : (
					<Datagrid>
						<ShopLogo source='logo' />
						<TextField source='name.zh' label='中文名称' />
						<TextField
							source='name.ug'
							label='维文名称'
							className={classes.ugTitle}
						/>
						<NumberField
							source='exchange_rate'
							label='积分规则（元/积分）'
							{...currencyProps}
						/>
						<NumberField
							source='per_day_alert'
							label='每日营业额预警（元）'
							{...currencyProps}
						/>
						<NumberField
							source='per_order_alert'
							label='每单金额预警（元）'
							{...currencyProps}
						/>
						<ImageField
							onClick={(e) => handleImg(e)}
							source='qrcode'
							label='专属二维码'
							className={classes.pic}
						/>
						<ReferenceField source='floor_id' label='位置' reference='floors'>
							<ChipField source='name.zh' />
						</ReferenceField>
						<TextField source='shop_number' label='商圈店铺编号' />
						<ReferenceArrayField
							source='auditor_ids'
							reference='auditors'
							label='核销员'
						>
							<SingleFieldList>
								<ChipField style={{ width: 80 }} source='user_id' />
							</SingleFieldList>
						</ReferenceArrayField>
						<EditButton />
						<DeleteButton />
					</Datagrid>
				)}
			</List>
			<Modal
				className={classes.modal}
				open={open}
				onClose={handleClose}
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				<div>
					<img className={classes.bigPic} src={img} title='详情' alt='' />
				</div>
			</Modal>
		</>
	);
};

const ShopTitle = ({ record }) => {
	return <span>店铺名 {record ? `"${record.name.zh}"` : ''}</span>;
};

export const ShopsEdit = (props) => {
	return (
		<Edit actions={<TopBarActions />} title={<ShopTitle />} {...props}>
			<SimpleForm toolbar={<CreateToolbar />}>
				<TextInput source='name.zh' label='中文名称' />
				<TextInput source='name.ug' label='维文名称' />
				<NumberInput source='exchange_rate' label='积分规则（元/积分）' />
				<NumberInput source='per_day_alert' label='每日营业额预警（元）' />
				<NumberInput source='per_order_alert' label='每单金额预警（元）' />
				<ReferenceInput source='floor_id' label='位置' reference='floors'>
					<SelectInput optionText='name.zh' />
				</ReferenceInput>
				<TextInput source='shop_number' label='商圈店铺编号' />
				<ReferenceInput
					label='店铺分类'
					reference='shop/categories'
					source='shop_category_id'
				>
					<SelectInput optionText='name.zh' />
				</ReferenceInput>
				<ImageInput
					source='logo'
					label='店铺logo'
					accept='image/*'
					format={(v) => ({ src: v })}
				>
					<ImageField source='src' />
				</ImageInput>
			</SimpleForm>
		</Edit>
	);
};

export const ShopsCreate = (props) => {
	return (
		<Create title='新增店铺' {...props} actions={<TopBarActions />}>
			<SimpleForm toolbar={<CreateToolbar />}>
				<TextInput source='name.zh' label='中文名称' />
				<TextInput source='name.ug' label='维文名称' />
				<NumberInput source='exchange_rate' label='积分规则（元/积分）' />
				<NumberInput source='per_day_alert' label='每日营业额预警（元）' />
				<NumberInput source='per_order_alert' label='每单金额预警（元）' />
				<ReferenceInput source='floor_id' label='位置' reference='floors'>
					<SelectInput optionText='name.zh' />
				</ReferenceInput>
				<TextInput source='shop_number' label='商圈店铺编号' />
				<ReferenceInput
					label='店铺分类'
					reference='shop/categories'
					source='shop_category_id'
				>
					<SelectInput optionText='name.zh' />
				</ReferenceInput>
				<ImageInput source='logo' label='店铺logo' accept='image/*'>
					<ImageField source='src' />
				</ImageInput>
			</SimpleForm>
		</Create>
	);
};
