import {
  Button,
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { stringify } from "qs";
import { useEffect, useState } from "react";
import {
  AutocompleteInput,
  Datagrid,
  DeleteButton,
  EditButton,
  FunctionField,
  ImageField,
  List,
  NumberField,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  SimpleList,
  Toolbar,
  useNotify,
  useRecordContext,
} from "react-admin";
import { useForm, useFormContext } from "react-hook-form";
import { httpClient } from "../http";

import useStyles from "./useStyles";

export const MemberGiftList = (props) => {
  const isSmall = useMediaQuery("(max-width:600px)");
  const formContext = useFormContext();
  const sendForm = useForm("send-member-gift");
  const [selectedGift, setSelectedGift] = useState(null);
  const notify = useNotify();
  useEffect(() => {
    console.log("🚀 ~ useEffect ~ selectedGift:", selectedGift);
    sendForm.reset();
    if (!selectedGift) {
    }
  }, [selectedGift, sendForm]);
  const handleClose = () => {
    setSelectedGift(null);
  };
  const classes = useStyles();

  const CouEdit = () => {
    const record = useRecordContext();

    return (
      <Button
        style={{ width: 20, fontSize: 10 }}
        onClick={() => {
          setSelectedGift(record);
        }}
        variant="contained"
        color="primary"
        disableElevation
      >
        发放
      </Button>
    );
  };
  const filterTouser = (searchText) => ({ mobile: searchText });

  const mobileMatch = (filter, choice) =>
    choice &&
    typeof choice.mobile === "string" &&
    choice.mobile.includes(`${filter}`);

  const optionText = (record) => `${record?.name} ${record?.mobile}`;

  const inputNumber = (e) => {
    e.target.value = e.target.value.replace(/[^0-9.]/g, "");
  };

  const GiftToolbar = ({ ...props }) => {
    const { getValues } = useFormContext();

    const url = `member-gift/${selectedGift?.id}/send`;
    const sendGift = async () => {
      const { user_id, quantity } = getValues();

      const res = await httpClient.post(
        url,
        stringify({
          user_id,
          quantity,
        }),
      );
      if (res.code === 400) {
        notify(res.data, { type: "error" });
      }
      setSelectedGift(null);
    };

    return (
      <Toolbar {...props}>
        <SaveButton label="保存" onClick={sendGift} invalid={props.invalid} />
      </Toolbar>
    );
  };

  return (
    <>
      <List title="会员礼品" {...props}>
        {isSmall ? (
          <SimpleList
            primaryText={(record) => (
              <span>
                {record.name.zh}{" "}
                <span className={classes.ugTitle}>{record.name.ug}</span>
              </span>
            )}
            secondaryText={(record) => (
              <span>所需积分 {record.exchange_points}</span>
            )}
          />
        ) : (
          <Datagrid>
            <ImageField
              source="image.zh"
              label="图片"
              classes={{ image: classes.image }}
            />
            <FunctionField
              label="名称"
              render={(record) => {
                return (
                  <div>
                    <div>{record.name.zh}</div>
                    <div>{record.name.ug}</div>
                  </div>
                );
              }}
            />

            <NumberField source="exchange_points" label="所需积分" />
            <NumberField source="limit_per_user" label="每人限领" />
            <NumberField source="total" label="总数" />
            <NumberField source="quantity" label="剩余数量" />
            <div style={{ textDecoration: "line-through" }}>
              <NumberField
                source="market_price"
                label="市场价格"
                options={{ style: "currency", currency: "CNY" }}
              />
            </div>
            <NumberField
              source="price"
              label="实际价格"
              options={{ style: "currency", currency: "CNY" }}
            />
            <CouEdit />
            <EditButton label="编辑" />
            <DeleteButton
              mutationMode="pessimistic"
              confirmTitle=""
              label="删除"
            />
          </Datagrid>
        )}
      </List>
      <Drawer
        variant="persistent"
        open={!!selectedGift}
        anchor="right"
        onClose={handleClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.root}>
          <div className={classes.title}>
            <Typography variant="h6">发放优惠券</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <SimpleForm
            name="send-member-gift"
            className={classes.form}
            toolbar={<GiftToolbar props={props.resource} recordId={props.id} />}
          >
            <ReferenceInput
              reference="users"
              source="user_id"
              label="客户姓名"
              isRequired
            >
              <AutocompleteInput
                filterToQuery={filterTouser}
                optionText={optionText}
                optionValue="id"
                matchSuggestion={mobileMatch}
              />
            </ReferenceInput>
            <NumberInput
              min="0"
              onInput={inputNumber}
              source="quantity"
              label="数量"
              isRequired
            />
          </SimpleForm>
        </div>
      </Drawer>
    </>
  );
};

export default MemberGiftList;
