import {
	ListButton,
	SaveButton,
	Toolbar,
	TopToolbar,
	useRecordContext,
	Pagination as RaPagination,
	PaginationActions as RaPaginationActions,
	useListContext,
} from 'react-admin';
import { makeStyles, Avatar } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'nowrap',
		alignItems: 'center',
	},
	avatar: {
		marginRight: theme.spacing(1),
		marginTop: -theme.spacing(0.5),
		marginBottom: -theme.spacing(0.5),
	},
}));

export const TopBarActions = ({ basePath }) => (
	<TopToolbar>
		<ListButton basePath={basePath} label='返回列表' />
	</TopToolbar>
);

export const TopSuperActions = () => {
	const navigate = useNavigate();

	return (
		<TopToolbar>
			<ListButton onClick={() => navigate(-2)} label='返回主列表' />
		</TopToolbar>
	);
};

export const TopBackActions = () => {
	const navigate = useNavigate();

	return (
		<TopToolbar>
			<ListButton onClick={() => navigate(-1)} label='返回列表' />
		</TopToolbar>
	);
};

export const CreateToolbar = (props) => (
	<Toolbar {...props}>
		<SaveButton label='保存并返回' redirect='list' submitOnEnter={true} />
		<SaveButton
			label='保存并继续添加'
			redirect={false}
			submitOnEnter={false}
			variant='text'
		/>
	</Toolbar>
);

const AvatarField = ({ record, size = '25', className }) =>
	record ? (
		<Avatar
			src={`${record.avatar}?size=${size}x${size}`}
			style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
			className={className}
		/>
	) : null;

export const FullNameField = ({ record, size }) => {
	const classes = useStyles();
	return record ? (
		<div className={classes.root}>
			<AvatarField className={classes.avatar} record={record} size={size} />
			{record.nickname}
		</div>
	) : null;
};

export const UsersGender = ({ source }) => {
	const record = useRecordContext();
	let gender = '';
	if (record) {
		switch (record.gender) {
			case '0':
				gender = '未指定';
				break;
			case 0:
				gender = '未指定';
				break;
			case '1':
				gender = '男';
				break;
			case 1:
				gender = '男';
				break;
			case '2':
				gender = '女';
				break;
			case 2:
				gender = '女';
				break;
			default:
				break;
		}
	}
	return gender;
};

export const PointsType = ({ source }) => {
	const record = useRecordContext();
	let type = '';
	if (record) {
		switch (record.type) {
			case 0:
				type = '单选';
				break;
			case 1:
				type = '多选';
				break;
			default:
				break;
		}
	}
	return type;
};

const PaginationActions = (props) => {
	return (
		<RaPaginationActions
			{...props}
			// these props are passed down to the MUI <Pagination> component
			color='primary'
			showFirstButton
			showLastButton
		/>
	);
};

export const Pagination = (props) => {
	const { setPage } = useListContext();
	const onPage = (e) => {
		setPage(e.target.value);
	};

	return (
		<div style={{ width: '100%', display: 'flex', textAlign: 'center' }}>
			<RaPagination {...props} ActionsComponent={PaginationActions} />
			<input
				style={{
					width: '50px',
					height: '20px',
					marginTop: '12px',
					textAlign: 'center',
				}}
				onChange={onPage}
			/>
		</div>
	);
};
