import {
	Datagrid,
	SimpleList,
	TextField,
	ListContextProvider,
	useListController,
	useRefresh,
} from 'react-admin';
import { Pagination } from './components';
import { useMediaQuery } from '@material-ui/core';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, message } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { httpClient } from './http';
import { stringify } from 'qs';

export const ExportReportList = (props) => {
	const [messageApi, contextHolder] = message.useMessage();
	const refresh = useRefresh();
	const isSmall = useMediaQuery('(max-width:600px)');

	const onExport = async (value) => {
		if (!value.range) {
			messageApi.open({
				type: 'warning',
				content: '请选择导出日期！',
			});
			return;
		}
		const start_at = dayjs(value.range[0]).format('YYYY-MM-DD');
		const end_at = dayjs(value.range[1]).format('YYYY-MM-DD');
		await httpClient
			.post(
				'export',
				stringify({
					start_at,
					end_at,
				})
			)
			.then((res) => {
				refresh();
			})
			.catch((err) => {
				messageApi.open({
					type: 'error',
					content: '导出失败！',
				});
			});
	};

	return (
		<ListContextProvider
			title='报表导出'
			value={useListController({
				basePath: props.match?.path,
				resource: 'export/list',
			})}
		>
			{contextHolder}
			<div style={{ display: 'flex' }}>
				<div style={{ flex: '1' }}>
					<div>
						<Form onFinish={onExport} style={{ display: 'flex' }}>
							<Form.Item name='range'>
								<DatePicker.RangePicker
									locale={locale}
									style={{
										textAlign: 'center',
										margin: '0 20px 0 20px',
										height: '40px',
										width: '400px',
									}}
								/>
							</Form.Item>
							<Form.Item>
								<Button
									htmlType='submit'
									type='primary'
									icon={<DownloadOutlined />}
									size='large'
								>
									导出
								</Button>
							</Form.Item>
						</Form>
					</div>
				</div>
			</div>
			{isSmall ? (
				<SimpleList
					primaryText={(record) => <span>开始时间{record?.start_at} </span>}
					secondaryText={(record) => <span>结束时间 {record?.end_at}</span>}
				/>
			) : (
				<Datagrid>
					<TextField source='start_at' label='开始时间' />
					<TextField source='end_at' label='结束时间' />
					<TextField source='name' label='报表名称' />
				</Datagrid>
			)}
			<Pagination />
		</ListContextProvider>
	);
};
