import { RadioButtonGroupInput, DateInput, SimpleForm } from 'react-admin';
import { Button } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { useRef } from 'react';
import { httpClient } from './http';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '400px',
		height: '200px',
		marginRight: '70px',
	},
	lroot: {
		width: '400px',
		height: '200px',
	},
}));

const Dashboard = () => {
	const onSubmit = useRef();
	const classes = useStyles();
	const [openDate, setOpenDate] = useState(false);
	const [list, setList] = useState(null);
	const navigate = useNavigate();
	onSubmit.current = async (inputData) => {
		let type = null;
		let start_date = null;
		let end_date = null;
		if (openDate === false) {
			type = inputData?.type ? inputData?.type : 0;
		} else {
			start_date = inputData?.start_date ? inputData?.start_date : null;
			end_date = inputData?.end_date ? inputData?.end_date : null;
		}

		const res = await httpClient.get('data/member/info', {
			params: {
				type,
				start_date,
				end_date,
			},
		});
		if (res.status === 401) {
			return navigate({
				pathname: '/login',
			});
		}
		setList(res.data);
	};

	const FormBody = ({ handleSubmit }) => {
		const onFab = () => {
			if (openDate === true) {
				setOpenDate(false);
			} else {
				setOpenDate(true);
			}
		};

		return (
			<>
				{openDate === false ? (
					<RadioButtonGroupInput
						defaultValue={0}
						source='type'
						label='请选择'
						choices={[
							{ id: 0, name: '今天' },
							{ id: 1, name: '昨天' },
							{ id: 2, name: '本周' },
							{ id: 3, name: '本月' },
						]}
					/>
				) : null}
				{openDate === true ? (
					<>
						<DateInput source='start_date' label='开始日期(非必须)' />
						<DateInput source='end_date' label='结束日期(非必须)' />
					</>
				) : null}
				<Fab
					size='small'
					color='primary'
					aria-label='add'
					style={{ marginLeft: '20px' }}
					onClick={() => onFab()}
				>
					<SyncAltIcon />
				</Fab>
				<Button
					variant='contained'
					color='primary'
					type='submit'
					style={{ margin: '20px' }}
				>
					查询
				</Button>
			</>
		);
	};

	const ChartList = () => {
		const columns = [
			{ field: 'id', headerName: '排名', width: 130 },
			{ field: 'nickname', headerName: '会员昵称', width: 200 },
			{ field: 'amount', headerName: '消费金额(元)', width: 200 },
		];
		const rows = list === null ? [] : list.member_rank;
		if (rows !== null) {
			for (let i = 0; i < rows.length; i++) {
				rows[i].id = i + 1;
				rows[i].nickname = rows[i].wechat_user.nickname;
			}
		}
		return <DataGrid rows={rows} columns={columns} pageSize={5} />;
	};

	useEffect(() => {
		onSubmit.current();
	}, []);

	const auth = JSON.parse(localStorage.getItem('auth'));
	if (!auth || !auth.access_token) {
		navigate({
			pathname: '/login',
		});
		return <></>;
	}

	return (
		<div>
			<div style={{ margin: '50px 0 50px 0 ', textAlign: 'center' }}>
				<Card>
					<CardContent>
						<Typography gutterBottom variant='h2' component='h1'>
							会员总人数：{list === null ? 0 : list?.total}
						</Typography>
					</CardContent>
				</Card>
			</div>
			<div style={{ textAlign: 'center' }}>
				<SimpleForm
					toolbar={false}
					onSubmit={onSubmit.current}
					component={FormBody}
				/>
			</div>
			<div
				style={{
					width: '100%',
					display: 'flex',
					borderBottom: '2px dotted #999',
				}}
			>
				<div
					style={{
						margin: '10px auto',
						display: 'flex',
					}}
				>
					<div
						style={{
							padding: '60px',
						}}
					>
						<Card className={classes.root}>
							<CardContent>
								<div
									style={{
										textAlign: 'center',
										marginBottom: '60px',
									}}
								>
									<Typography gutterBottom variant='h3' component='h1'>
										{list === null
											? 0
											: list?.sales_amount === null
											? 0
											: list?.sales_amount}
									</Typography>
								</div>
								<div style={{ textAlign: 'center' }}>
									<Typography variant='h5' color='textSecondary' component='h2'>
										交易额
									</Typography>
								</div>
							</CardContent>
						</Card>
					</div>
					{/* <Divider orientation="vertical" flexItem /> */}
					<div style={{ padding: '60px', marginLeft: '100px' }}>
						<Card className={classes.lroot}>
							<CardContent>
								<div
									style={{
										textAlign: 'center',
										marginBottom: '60px',
									}}
								>
									<Typography gutterBottom variant='h3' component='h1'>
										{list === null
											? 0
											: list?.orders_count === null
											? 0
											: list.orders_count}
									</Typography>
								</div>
								<div style={{ textAlign: 'center' }}>
									<Typography variant='h5' color='textSecondary' component='h2'>
										订单数量
									</Typography>
								</div>
							</CardContent>
						</Card>
					</div>
				</div>
			</div>
			<div
				style={{
					width: '100%',
					display: 'flex',
				}}
			>
				<div
					style={{
						margin: '10px auto',
						display: 'flex',
					}}
				>
					<div style={{ padding: '50px' }}>
						<Card className={classes.root}>
							<CardContent>
								<div
									style={{
										textAlign: 'center',
										marginBottom: '60px',
									}}
								>
									<Typography gutterBottom variant='h3' component='h1'>
										{list === null
											? 0
											: list?.points_increased === null
											? 0
											: list.points_increased}
									</Typography>
								</div>
								<div style={{ textAlign: 'center' }}>
									<Typography variant='h5' color='textSecondary' component='h2'>
										新增积分
									</Typography>
								</div>
							</CardContent>
						</Card>
					</div>
					<div style={{ padding: '50px', marginLeft: '110px' }}>
						<Card className={classes.lroot}>
							<CardContent>
								<div
									style={{
										textAlign: 'center',
										marginBottom: '60px',
									}}
								>
									<Typography gutterBottom variant='h3' component='h1'>
										{list === null
											? 0
											: list?.points_usage === null
											? 0
											: list.points_usage}
									</Typography>
								</div>
								<div style={{ textAlign: 'center' }}>
									<Typography variant='h5' color='textSecondary' component='h2'>
										使用积分
									</Typography>
								</div>
							</CardContent>
						</Card>
					</div>
				</div>
			</div>
			<div style={{ margin: '50px 0 50px 0 ', textAlign: 'center' }}>
				<Card>
					<CardContent>
						<Typography gutterBottom variant='h3' component='h1'>
							新增会员：
							{list === null
								? 0
								: list?.new_members === null
								? 0
								: list.new_members}
						</Typography>
					</CardContent>
				</Card>
			</div>
			<div style={{ width: '40%', height: '40%' }}>
				<ChartList />
			</div>
		</div>
	);
};

export default Dashboard;
