import {
	Create,
	DeleteButton,
	Edit,
	EditButton,
	List,
	TextField,
	TextInput,
	SimpleForm,
	Datagrid,
	SimpleList,
} from 'react-admin';
import { useMediaQuery, makeStyles } from '@material-ui/core';
import { CreateToolbar, TopBarActions } from './components';

const useStyles = makeStyles(() => ({
	ugTitle: {
		fontFamily: 'UgFont',
	},
}));

export const FloorsList = (props) => {
	const isSmall = useMediaQuery('(max-width:600px)');
	const classes = useStyles();
	return (
		<List {...props} title='楼层管理'>
			{isSmall ? (
				<SimpleList
					primaryText={(record) => (
						<span>
							{record.name.zh}
							<span className={classes.ugTitle}> {record.name.ug}</span>
						</span>
					)}
				/>
			) : (
				<Datagrid>
					<TextField source='name.zh' label='中文名称' />
					<TextField source='name.ug' label='维文名称' />
					<EditButton />
					<DeleteButton />
				</Datagrid>
			)}
		</List>
	);
};

export const FloorsEdit = (props) => {
	return (
		<Edit {...props} actions={<TopBarActions />}>
			<SimpleForm toolbar={<CreateToolbar />}>
				<TextInput source='name.zh' label='中文名称' />
				<TextInput source='name.ug' label='维文名称' />
			</SimpleForm>
		</Edit>
	);
};

export const FloorsCreate = (props) => {
	return (
		<Create {...props} actions={<TopBarActions />}>
			<SimpleForm toolbar={<CreateToolbar />}>
				<TextInput source='name.zh' label='中文名称' />
				<TextInput source='name.ug' label='维文名称' />
			</SimpleForm>
		</Create>
	);
};
