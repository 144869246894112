import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  ugTitle: {
    fontFamily: "UgFont",
  },
  image: {
    width: 60,
    height: 60,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 800,
    height: "auto",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  page: {
    display: "flex",
  },
}));

export default useStyles;
