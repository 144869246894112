import { makeStyles } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import AssessmentIcon from "@material-ui/icons/Assessment";
import BusinessIcon from "@material-ui/icons/Business";
import CakeIcon from "@material-ui/icons/Cake";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import CategoryIcon from "@material-ui/icons/Category";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import DevicesIcon from "@material-ui/icons/Devices";
import UserIcon from "@material-ui/icons/Group";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import LocalTaxiIcon from "@material-ui/icons/LocalTaxi";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import StoreIcon from "@material-ui/icons/Store";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import ViewStreamIcon from "@material-ui/icons/ViewStream";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import clsx from "clsx";
import { useState } from "react";
import { MenuItemLink, useSidebarState } from "react-admin";
import SubMenu from "./SubMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    paddingBottom: (props) => (props.addPadding ? "80px" : "20px"),
  },
  open: {
    width: 240,
  },
  closed: {
    width: 55,
  },
}));

const Menu = ({ dense = false }) => {
  const classes = useStyles();
  const [open] = useSidebarState();
  const [state, setState] = useState({
    menuCatalog: false,
    menuPermission: false,
    menuMember: false,
    menuGifts: false,
    menuPark: false,
    menuReport: false,
    menuSetting: false,
    menuAds: false,
  });

  const auth = JSON.parse(localStorage.getItem("auth"));

  let manage_roles = false;
  let manage_admins = false;
  let manage_shops = false;
  let manage_advertisements = false;
  let manage_auditors = false;
  let manage_coupon = false;
  let manage_license = false;
  let manage_members = false;
  let manage_member_gifts = false;
  let manage_parking_coupon = false;
  let manage_supermarket_coupon = false;

  for (let i = 0; i < auth?.permission?.length; i++) {
    if (auth.permission[i] === "*") {
      manage_roles = true;
      manage_admins = true;
      manage_shops = true;
      manage_advertisements = true;
      manage_auditors = true;
      manage_coupon = true;
      manage_license = true;
      manage_members = true;
      manage_member_gifts = true;
      manage_parking_coupon = true;
      manage_supermarket_coupon = true;
      break;
    }
    if (auth.permission[i].name === "manage-roles") {
      manage_roles = true;
    }
    if (auth.permission[i].name === "manage-admins") {
      manage_admins = true;
    }
    if (auth.permission[i].name === "manage-shops") {
      manage_shops = true;
    }
    if (auth.permission[i].name === "manage-advertisements") {
      manage_advertisements = true;
    }
    if (auth.permission[i].name === "manage-auditors") {
      manage_auditors = true;
    }
    if (auth.permission[i].name === "manage-coupon") {
      manage_coupon = true;
    }
    if (auth.permission[i].name === "manage-license") {
      manage_license = true;
    }
    if (auth.permission[i].name === "manage-members") {
      manage_members = true;
    }
    if (auth.permission[i].name === "manage-member-gifts") {
      manage_member_gifts = true;
    }
    if (auth.permission[i].name === "manage-parking-coupon") {
      manage_parking_coupon = true;
    }
    if (auth.permission[i].name === "manage-supermarket-coupon") {
      manage_supermarket_coupon = true;
    }
  }

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
    >
      <MenuItemLink
        to="/data/member/info"
        primaryText="概览"
        leftIcon={<ViewComfyIcon />}
        dense={dense}
      />
      {manage_members ? (
        <SubMenu
          handleToggle={() => handleToggle("menuMember")}
          isOpen={state.menuMember}
          name="会员管理"
          icon={<CardMembershipIcon />}
          dense={dense}
        >
          <MenuItemLink
            to="/members"
            primaryText="会员管理"
            leftIcon={<CardMembershipIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/member/levels"
            primaryText="会员等级"
            leftIcon={<ViewStreamIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/member/rights"
            primaryText="会员权益"
            leftIcon={<LockOpenIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/member/birthdays"
            primaryText="会员今日生日提醒"
            leftIcon={<CakeIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/birthday/week"
            primaryText="会员本周生日提醒"
            leftIcon={<CakeIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/ponit/histories"
            primaryText="会员获取积分明细"
            leftIcon={<AccessTimeIcon />}
            dense={dense}
          />

          <MenuItemLink
            to="/ponit/use"
            primaryText="会员使用积分明细"
            leftIcon={<AccessTimeIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/data/member/growth"
            primaryText="会员增长统计"
            leftIcon={<AssessmentIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/data/member/point/increment"
            primaryText="会员积分增长统计"
            leftIcon={<AssessmentIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/data/member/point/usage"
            primaryText="会员积分使用统计"
            leftIcon={<AssessmentIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/data/member/levels"
            primaryText="会员等级分析"
            leftIcon={<AssessmentIcon />}
            dense={dense}
          />
        </SubMenu>
      ) : null}
      {manage_member_gifts ? (
        <SubMenu
          handleToggle={() => handleToggle("menuGifts")}
          isOpen={state.menuGifts}
          name="礼品管理"
          icon={<CardGiftcardIcon />}
          dense={dense}
        >
          <MenuItemLink
            to="/member/gifts"
            primaryText="会员礼品"
            leftIcon={<CardGiftcardIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/member/gift/orders"
            primaryText="礼品订单"
            leftIcon={<AllInboxIcon />}
            dense={dense}
          />
        </SubMenu>
      ) : null}
      {manage_shops ? (
        <SubMenu
          handleToggle={() => handleToggle("menuCatalog")}
          isOpen={state.menuCatalog}
          name="店铺管理"
          icon={<StoreIcon />}
          dense={dense}
        >
          <MenuItemLink
            to="/shop/industries"
            primaryText="店铺业态"
            leftIcon={<CategoryIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/shop/categories"
            primaryText="店铺分类"
            leftIcon={<CategoryIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/floors"
            primaryText="楼层"
            leftIcon={<BusinessIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/shops"
            primaryText="店铺"
            leftIcon={<StoreIcon />}
            dense={dense}
          />
          {manage_auditors ? (
            <MenuItemLink
              to="/auditors"
              primaryText="核销员"
              leftIcon={<PlaylistAddCheckIcon />}
              dense={dense}
            />
          ) : null}
        </SubMenu>
      ) : null}
      {manage_advertisements ? (
        <SubMenu
          handleToggle={() => handleToggle("menuAds")}
          isOpen={state.menuAds}
          name="广告文章管理"
          icon={<PhotoLibraryIcon />}
          dense={dense}
        >
          <MenuItemLink
            to="/advertisements"
            primaryText="广告"
            leftIcon={<PhotoLibraryIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/articles"
            primaryText="文章"
            leftIcon={<PhotoLibraryIcon />}
            dense={dense}
          />
        </SubMenu>
      ) : null}

      {manage_supermarket_coupon ? (
        <MenuItemLink
          to="/supermarket-coupons"
          primaryText="超市优惠券"
          leftIcon={<ShoppingCartIcon />}
          dense={dense}
        />
      ) : null}
      {manage_coupon ? (
        <MenuItemLink
          to="/coupons"
          primaryText="通用优惠券"
          leftIcon={<ConfirmationNumberIcon />}
          dense={dense}
        />
      ) : null}
      {manage_parking_coupon || manage_license ? (
        <SubMenu
          handleToggle={() => handleToggle("menuPark")}
          isOpen={state.menuPark}
          name="停车管理"
          icon={<LocalTaxiIcon />}
          dense={dense}
        >
          {manage_parking_coupon ? (
            <MenuItemLink
              to="/parking/coupons"
              primaryText="停车优惠券"
              leftIcon={<LocalTaxiIcon />}
              dense={dense}
            />
          ) : null}
          {manage_license ? (
            <MenuItemLink
              to="/parking/orders"
              primaryText="停车订单"
              leftIcon={<LocalParkingIcon />}
              dense={dense}
            />
          ) : null}
        </SubMenu>
      ) : null}
      <MenuItemLink
        to="/point/activities"
        primaryText="积分活动管理"
        leftIcon={<LocalOfferIcon />}
        dense={dense}
      />
      <SubMenu
        handleToggle={() => handleToggle("menuSetting")}
        isOpen={state.menuSetting}
        name="系统设置"
        icon={<PhonelinkSetupIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/weapp"
          primaryText="小程序主页面"
          leftIcon={<DevicesIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/member/extra/infos"
          primaryText="注册积分"
          leftIcon={<DevicesIcon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuReport")}
        isOpen={state.menuReport}
        name="报表"
        icon={<CardMembershipIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/export"
          primaryText="报表导出"
          leftIcon={<LibraryBooksIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/report"
          primaryText="消费明细报表"
          leftIcon={<UserIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/invitations"
          primaryText="店铺专属二维码统计"
          leftIcon={<UserIcon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        key="权限管理"
        handleToggle={() => handleToggle("menuPermission")}
        isOpen={state.menuPermission}
        name="权限管理"
        icon={<VpnKeyIcon />}
        dense={dense}
      >
        {manage_admins ? (
          <MenuItemLink
            to="/admins"
            primaryText="管理员"
            leftIcon={<UserIcon />}
            dense={dense}
          />
        ) : null}
        {manage_roles ? (
          <MenuItemLink
            to="/roles"
            primaryText="角色"
            leftIcon={<VpnKeyIcon />}
            dense={dense}
          />
        ) : null}
      </SubMenu>
    </div>
  );
};

export default Menu;
