import {
	Create,
	Datagrid,
	List,
	SimpleList,
	SimpleForm,
	SelectInput,
	ImageInput,
	ImageField,
	EditButton,
	DeleteButton,
	Edit,
	SelectField,
	Filter,
	RadioButtonGroupInput,
	ReferenceInput,
	AutocompleteInput,
	DateTimeInput,
	useRecordContext,
	Button,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { TopBarActions, CreateToolbar } from './components';
import { imageInputFormat } from './constants';
import { httpClient } from './http';
import { useState } from 'react';

const adTypes = [
	{ id: 1, name: '跳出广告' },
	{ id: 2, name: '首屏广告' },
	{ id: 3, name: '首页广告' },
];

const adUrls = [
	{ id: '/pages/coupons/index', name: '商铺优惠券' },
	{ id: '/pages/smcoupon/index', name: '超市优惠券' },
	{ id: '/pages/articleDetail/index', name: '文章' },
];
const PointsFilter = (props) => (
	<Filter {...props}>
		<RadioButtonGroupInput
			alwaysOn
			label='广告类型'
			source='type'
			choices={adTypes}
		/>
		{/* <DateInput label="开始日期" source="start_at" /> */}
	</Filter>
);

const filterToQuery = (searchText) => ({ title: searchText });

const optionText = (record) => `${record?.title.zh} ${record?.title.ug}`;

const SendStatus = ({ source, ...props }) => {
	const record = useRecordContext();
	const [status, setStatus] = useState(record.sent_at);
	return status ? (
		<div>已推送</div>
	) : (
		<Button
			{...props}
			startIcon={<SendIcon />}
			color='primary'
			onClick={async () => {
				const res = await httpClient.get(
					`advertisement/${record.id}/send/notification`,
					{}
				);
				if (res.status >= 200 && res.status < 300) {
					setStatus('send');
				}
			}}
		/>
	);
};

export const AdvertisementsList = (props) => {
	const isSmall = useMediaQuery('(max-width:600px)');

	return (
		<List title='广告' {...props} filters={<PointsFilter />}>
			{isSmall ? (
				<SimpleList
					primaryText={(record) => record.url}
					secondaryText={(record) => record.type}
				/>
			) : (
				<Datagrid>
					<ImageField source='image.zh' label='中文图片' />
					<ImageField source='image.ug' label='维文图片' />
					<SelectField source='type' label='广告类型' choices={adTypes} />
					<SelectField source='url' label='连接页面' choices={adUrls} />
					<SendStatus source='send_at' label='广告推送' />
					<EditButton />
					<DeleteButton />
				</Datagrid>
			)}
		</List>
	);
};

export const AdvertisementsEdit = (props) => {
	return (
		<Edit {...props} title='编辑广告' actions={<TopBarActions />}>
			<SimpleForm toolbar={<CreateToolbar />}>
				<SelectInput
					source='type'
					optionText='name'
					optionValue='id'
					label='广告类型'
					choices={adTypes}
				/>
				<SelectInput
					source='url'
					optionText='name'
					optionValue='id'
					label='连接页面'
					choices={adUrls}
				/>
				<ReferenceInput reference='articles' source='article_id' label='文章'>
					<AutocompleteInput
						filterToQuery={filterToQuery}
						optionText={optionText}
						optionValue='id'
					/>
				</ReferenceInput>
				<ImageInput
					source='image.zh'
					label='中文图片'
					accept='image/*'
					format={imageInputFormat}
				>
					<ImageField source='src' />
				</ImageInput>
				<ImageInput
					source='image.ug'
					label='维文图片'
					accept='image/*'
					format={imageInputFormat}
				>
					<ImageField source='src' />
				</ImageInput>
			</SimpleForm>
		</Edit>
	);
};

export const AdvertisementsCreate = (props) => {
	return (
		<Create {...props} title='新增广告' actions={<TopBarActions />}>
			<SimpleForm toolbar={<CreateToolbar />}>
				<SelectInput
					source='type'
					optionText='name'
					optionValue='id'
					label='广告类型'
					choices={adTypes}
				/>
				<SelectInput
					source='url'
					optionText='name'
					optionValue='id'
					label='连接页面'
					choices={adUrls}
				/>
				<ReferenceInput reference='articles' source='article_id' label='文章'>
					<AutocompleteInput
						filterToQuery={filterToQuery}
						optionText={optionText}
						optionValue='id'
					/>
				</ReferenceInput>
				<DateTimeInput source='start_at' label='开始时间' />
				<DateTimeInput source='end_at' label='结束时间' />
				<ImageInput source='image.zh' label='中文图片' accept='image/*'>
					<ImageField source='src' />
				</ImageInput>
				<ImageInput source='image.ug' label='维文图片' accept='image/*'>
					<ImageField source='src' />
				</ImageInput>
			</SimpleForm>
		</Create>
	);
};
