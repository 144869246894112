import {
	List,
	Datagrid,
	TextField,
	EditButton,
	Edit,
	SimpleForm,
	SimpleList,
	DeleteButton,
	ReferenceInput,
	Create,
	BooleanInput,
	ReferenceField,
	useRecordContext,
	useUpdate,
	AutocompleteInput,
} from 'react-admin';
import { useMediaQuery, Switch } from '@material-ui/core';
import { CreateToolbar, TopBarActions } from './components';

const AuditorStatus = ({ source, basePath, ...props }) => {
	const record = useRecordContext();
	const [update, { isFetching }] = useUpdate();
	return (
		<Switch
			{...props}
			checked={Number(record[`${source}`]) === 1}
			color='primary'
			disabled={isFetching}
			onChange={(event) => {
				update(
					'auditors',
					record.id,
					source === 'status'
						? { status: event.target.checked ? 1 : 0 }
						: { is_member_gift_auditor: event.target.checked ? 1 : 0 },
					record
				);
			}}
		/>
	);
};

export const AuditorsList = (props) => {
	const isSmall = useMediaQuery('(max-width:600px)');
	return (
		<List {...props} title='核销员管理'>
			{isSmall ? (
				<SimpleList
					primaryText={(record) => record.user_id}
					secondaryText={(record) => (
						<Switch checked={Number(record.status) === 1} color='primary' />
					)}
					linkType={(record) => (record.canEdit ? 'edit' : 'show')}
				/>
			) : (
				<Datagrid>
					<ReferenceField reference='shops' source='shop_id' label='店铺'>
						<TextField source='name.zh' />
					</ReferenceField>
					<ReferenceField reference='users' source='user_id' label='核销员工'>
						<TextField source='name' />
					</ReferenceField>
					<AuditorStatus source='status' label='状态' />
					<AuditorStatus source='is_member_gift_auditor' label='礼品核销权限' />
					<EditButton />
					<DeleteButton />
				</Datagrid>
			)}
		</List>
	);
};

const filterToQuery = (searchText) => ({ name: searchText });

const filterToUser = (searchText) => ({ mobile: searchText });

const optionText = (record) => `${record?.name} ${record?.mobile}`;

const mobileMatch = (filter, choice) =>
	choice &&
	typeof choice.mobile === 'string' &&
	choice.mobile.includes(`${filter}`);

const nameMatch = (filter, choice) =>
	choice.name && choice.name.zh && choice.name.zh.includes(`${filter}`);

export const AuditorsEdit = (props) => (
	<Edit title='核销员' {...props} actions={<TopBarActions />}>
		<SimpleForm>
			<ReferenceInput reference='shops' source='shop_id' label='店铺'>
				<AutocompleteInput
					optionText='name.zh'
					filterToQuery={filterToQuery}
					optionValue='id'
					matchSuggestion={nameMatch}
				/>
			</ReferenceInput>
			<ReferenceInput
				required
				reference='users'
				source='user_id'
				label='核销员工'
			>
				<AutocompleteInput
					filterToQuery={filterToUser}
					required
					optionText={optionText}
					optionValue='id'
					matchSuggestion={mobileMatch}
				/>
			</ReferenceInput>
			<BooleanInput
				label='状态'
				source='status'
				format={(r) => r === 1}
				parse={(i) => (i === true ? 1 : 0)}
				defaultValue={1}
			/>
			<BooleanInput
				label='礼品核销权限'
				source='is_member_gift_auditor'
				format={(r) => r === 1}
				parse={(i) => (i === true ? 1 : 0)}
				defaultValue={1}
			/>
		</SimpleForm>
	</Edit>
);

export const AuditorsCreate = (props) => (
	<Create title='核销员' {...props} actions={<TopBarActions />}>
		<SimpleForm toolbar={<CreateToolbar />}>
			<ReferenceInput reference='shops' source='shop_id' label='店铺'>
				<AutocompleteInput
					filterToQuery={filterToQuery}
					optionText='name.zh'
					optionValue='id'
					matchSuggestion={nameMatch}
				/>
			</ReferenceInput>
			<ReferenceInput
				reference='users'
				source='user_id'
				label='核销员工'
				required
			>
				<AutocompleteInput
					optionText={optionText}
					filterToQuery={filterToUser}
					optionValue='id'
					matchSuggestion={mobileMatch}
					required
				/>
			</ReferenceInput>
			<BooleanInput
				label='状态'
				source='status'
				format={(r) => r === 1}
				parse={(i) => (i === true ? 1 : 0)}
				defaultValue={1}
			/>
			<BooleanInput
				label='礼品核销权限'
				source='is_member_gift_auditor'
				format={(r) => r === 1}
				parse={(i) => (i === true ? 1 : 0)}
				defaultValue={1}
			/>
		</SimpleForm>
	</Create>
);
