import {
	BulkDeleteButton,
	BulkExportButton,
	Create,
	Datagrid,
	DeleteButton,
	Edit,
	EditButton,
	List,
	NumberInput,
	SimpleForm,
	SimpleList,
	TextField,
	TextInput,
	BooleanInput,
	DateField,
	Filter,
	SelectInput,
} from 'react-admin';
import { useMediaQuery, makeStyles } from '@material-ui/core';
import {
	CreateToolbar,
	TopSuperActions,
	TopBackActions,
	Pagination,
} from './components';
import { Fragment, useEffect, useRef, useState } from 'react';
import { httpClient } from './http';

const useStyles = makeStyles(() => ({
	ugTitle: {
		fontFamily: 'UgFont',
	},
	switch: {
		display: 'flex',
	},
	switchText: {
		padding: '7px',
	},
	totalList: {
		display: 'flex',
	},
	insideList: {
		display: 'flex',
		margin: 25,
	},
	insideItem: {
		marginRight: '30px',
	},
}));

const SupermarketFilter = (props) => {
	return (
		<Filter {...props}>
			<TextInput label='搜索' source='mobile' alwaysOn resettable />
			<SelectInput
				label='是否核销'
				source='is_audited'
				alwaysOn
				resettable
				emptyText='全部'
				choices={[
					{ id: 0, name: '未核销' },
					{ id: 1, name: '已核销' },
				]}
			/>
			<SelectInput
				label='是否发送'
				source='is_sended'
				alwaysOn
				resettable
				emptyText='全部'
				choices={[
					{ id: 0, name: '未发送' },
					{ id: 1, name: '已发送' },
				]}
			/>
		</Filter>
	);
};

export const SupermarketShowList = (props) => {
	const [totalList, setTotalList] = useState(null);
	const id = localStorage.getItem('supermarketId');
	const isSmall = useMediaQuery('(max-width:600px)');
	const classes = useStyles();
	const onAllData = useRef();

	onAllData.current = async () => {
		const res = await httpClient.get(`data/supermarket/coupon/${id}/list`);
		setTotalList(res.data);
	};

	useEffect(() => {
		onAllData.current();
	}, []);

	const PostBulkActionButtons = (props) => (
		<Fragment>
			<BulkExportButton {...props} />
			{/* default bulk delete action */}
			<BulkDeleteButton {...props} />
		</Fragment>
	);

	return (
		<>
			<List
				{...props}
				pagination={<Pagination />}
				filters={<SupermarketFilter />}
				bulkActionButtons={<PostBulkActionButtons />}
			>
				{isSmall ? (
					<SimpleList
						primaryText={(record) => (
							<span>
								券码{' '}
								<span className={classes.ugTitle}>{record?.coupon_number}</span>
							</span>
						)}
						secondaryText={(record) => record.level_points}
					/>
				) : (
					<Datagrid>
						<TextField source='coupon_number' label='券码' />
						<DateField source='created_at' label='创建时间' />
						<TextField source='user.data.mobile' label='发放给' />
						<DateField source='updated_at' label='更新时间' />
						<TextField source='auditor.data.name' label='核销人' />
						<DateField source='audited_at' label='核销时间' />
						<EditButton />
						<DeleteButton confirmTitle='' mutationMode='pessimistic' />
					</Datagrid>
				)}
			</List>
			<div className={classes.totalList}>
				<div className={classes.insideList}>
					<div className={classes.insideItem}>
						全部子券数量： {totalList?.total}
					</div>
					<div className={classes.insideItem}>
						已发送子券数量：{totalList?.totalSended}
					</div>
					<div className={classes.insideItem}>
						已核销子券数量： {totalList?.totalAudited}
					</div>
				</div>
				<TopSuperActions />
			</div>
		</>
	);
};

export const SupermarketShowEdit = (props) => {
	return (
		<Edit {...props} title='编辑子超市优惠券' actions={<TopBackActions />}>
			<SimpleForm toolbar={<CreateToolbar />}>
				<TextInput source='coupon_number' label='券码' />
			</SimpleForm>
		</Edit>
	);
};

export const SupermarketShowCreate = (props) => {
	const [mult, setMult] = useState(false);
	const classes = useStyles();
	const handleChange = () => {
		setMult(!mult);
	};

	return (
		<Create {...props} title='新增子超市优惠券' actions={<TopBackActions />}>
			<SimpleForm toolbar={<CreateToolbar />}>
				<div className={classes.switch}>
					<div className={classes.switchText}>是否多券码添加</div>
					<BooleanInput
						checked={mult}
						onChange={handleChange}
						name='state'
						inputProps={{ 'aria-label': 'secondary checkbox' }}
					/>
				</div>
				{mult === true ? (
					<div>
						<TextInput source='range' label='券码范围' />
						<NumberInput source='quantity' label='数量' />
					</div>
				) : (
					<TextInput source='coupon_number' label='券码' />
				)}
			</SimpleForm>
		</Create>
	);
};
